/* You can add global styles to this file, and also import other style files */
@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";

.confirm-button {
  background-color: #b2d571 !important;
  border-radius: 30px;
}

.cancel-button {
  background-color: #f36f48 !important;
  border-radius: 30px;
}

.secondary-button {
  background-color: #999 !important;
  border-radius: 30px;
}

.edit-btn {
  background-color: #442dff !important;
}

.color-picker .saturation-lightness {
  display: none !important;
}

.color-picker .hue-alpha {
  display: none !important;
}

.color-picker .hex-text {
  display: none !important;
}

.color-picker .type-policy {
  display: none !important;
}

.multiselect-dropdown .dropdown-list {
  overflow: hidden;
}

.autocomplete-container {
  box-shadow: none !important;
}

.autocomplete-container input {
  border: 1px solid #ddd !important;
  border-radius: 30px !important;
}

.cdk-drag-preview {
  display: flex;
  justify-content: space-between;
}

.working-hours-row {
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
}

.working-hours-day {
  margin-right: 1rem;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  width: 7rem;
  font-size: 1.1rem;
}

.working-hours-row .time-range-wrapper {
  margin-left: 1rem;
}

.working-hours-switch .custom-control-label {
  margin-bottom: 0 !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  color: #000;
}

.working-hours-switch {
  cursor: pointer !important;
  margin-right: 0.5rem;
}

.btn-link {
  padding: 5px !important;
  margin: 0 !important;
}

.ngb-tp-input {
  padding: 5px !important;
}

.ngb-tp-meridian .btn {
  padding: 3px 5px !important;
}

.ngb-tp-input-container input {
  border-radius: 4px !important;
}

.ngb-tp-input-container {
  width: 2em !important;
}

.ngb-tp-spacer {
  width: 0.5em !important;
}

.sortable-column {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.sortable-column::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -5px;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  transform: translateY(-50%) translateX(100%);
}

.sortable-column-ascending::after {
  content: "^";
}

.sortable-column-descending::after {
  content: "^";
  transform: translateY(-50%) translateX(100%) rotate(180deg);
}

.required-field {
  position: relative;
}

.required-field::after {
  content: "*";
  color: red;
  font-size: 1rem;
}

.dish__food-science__filters-modal {
  max-width: 900px;
}
